<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <img
          src="@/assets/TestEquipment/koenen1-min.jpg"
          alt=""
          title="koenen1"
          class="img-responsive m-auto"
        />
        <div class="flex flex-wrap">
          <div class="w-1/2 md:w-1/3">
            <img
              width="267.2"
              height="265.5"
              src="@/assets/TestEquipment/koenen4-288x295-min.jpg"
              class="m-auto size-portfolio-two"
              alt="Koenen Apparatus"
            />
            <Paragraph>Koenen Apparatus</Paragraph>
          </div>
          <div class="w-1/2 md:w-1/3">
            <img
              width="307"
              height="275"
              src="@/assets/TestEquipment/koenen5-min.jpg"
              class="m-auto size-portfolio-two"
              alt="Koenen Operator Control Box"
            />
            <Paragraph>Koenen Operator Control Box</Paragraph>
          </div>
          <div class="w-1/2 md:w-1/3">
            <img
              width="307"
              height="230"
              src="@/assets/TestEquipment/koenen6-min.jpg"
              class="m-auto size-portfolio-two"
              alt="Koenen Burner Control Unit"
            />
            <Paragraph>Koenen Burner Control Unit</Paragraph>
          </div>
          <div class="w-1/2 md:w-1/3">
            <img
              width="307"
              height="210"
              src="@/assets/TestEquipment/koenen2-min.jpg"
              class="m-auto size-portfolio-two"
              alt="Koenen Tube"
            />
            <Paragraph>Koenen Tube</Paragraph>
          </div>
          <div class="w-1/2 md:w-1/3">
            <img
              width="87.84"
              height="122.88"
              src="@/assets/TestEquipment/koenen3-min.jpg"
              class="m-auto size-portfolio-two"
              alt=""
            />
            <Paragraph>Koenen Tube in Reusable Closing Device</Paragraph>
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="Koenen Test Appartus" size="large" />
        <Paragraph
          ><p>
            Safety Management Services, Inc. (SMS) manufactures the Koenen Test
            apparatus for clients who require in-house testing to determine the
            sensitivity of solid and liquid material to intense heat while under
            confinement. Data obtained from the Koenen test can be used in
            determining a substance’s shipping classification and for evaluating
            the degree of venting required to avoid an explosion during
            processing operations.
          </p>
          <p>
            This test uses a non-reusable open-ended flanged steel tube,
            reusable closing device with an orifice through which gases are
            vented, industrial propane cylinder, four burners, and a protective
            welded box.
          </p>
          <p>
            For the test, a substance is loaded into a flanged steel tube and a
            threaded collar is slipped onto the tube from below. A closing plate
            with orifice, through which the gases from the decomposition of the
            test substance escape, is fitted over the flanged tube and secured
            with a nut. The tube assembly is suspended between two rods in a
            heating and protective device. Heating is provided by four (4)
            propane burners that are lit simultaneously.
          </p>
          <p>
            The test is completed upon rupture of the tube or after heating the
            tube for a minimum of 5 minutes with no reaction. After each trial,
            the fragments of the tube, if any, are collected and weighed. The
            reaction is evaluated as an explosion if the tube is fragmented into
            three (3) or more pieces.<br />
            <img
              class="alignright size-full wp-image-12221"
              src="@/assets/TestEquipment/koenen0.gif"
              alt="Examples of Koenen Explosive Effect Types"
              width="275"
            /><br />
            SMS provides the Koenen Test Apparatus with several enhanced safety
            features that are invaluable to the safety of test personnel:
          </p>
          <ul>
            <li>
              Centralized Operator Control Box for remote operation capability.
              Operator Control Box is equipped with (a) a lockout key, (b)
              fail-close switch required for test startup, and (c) indicator
              lights for remotely monitoring the status of each burner. The
              cabling to the Operator Control Box is provided for remote
              operation of up to 50 feet. Longer cabling is available.
            </li>
            <li>
              Minimal explosive contamination hazards to personnel during
              cleanup and decontamination of the protective device through
              safety-by-design. The protective device has (a) no blind holes,
              (b) no metal threads in areas that can easily become contaminated
              by explosives, (c) full penetration welds in all possible areas,
              and (d) no cracks or crevices where explosives may build up over
              time. Threaded pieces outside the normal areas of potential
              contamination are plastic and replaceable.
            </li>
            <li>
              Independent ignition systems for each propane burner that, upon
              loss of flame at any burner, provide automatic try-for-re-ignition
              and shutdown/lockout of propane after 10 seconds of flameout.
            </li>
            <li>
              Remote propane supply cylinder can be removed to a safe location,
              distanced from the test apparatus and potentially generated
              fragments.
            </li>
            <li>
              Optional stainless steel burners are available for applications
              where strong oxidizing materials may have an adverse reaction with
              the standard brass burners.
            </li>
          </ul>
          <p>
            Additionally, the Koenen Test Apparatus includes the following
            design features:
          </p>
          <ul>
            <li>
              Spark electrodes located exterior to the heating and protective
              device, minimizing damage from fragments, debris, and residue.
            </li>
            <li>
              Quick disconnect connections on wiring and propane hose
              connections for quick and easy assembly/disassembly.
            </li>
            <li>Readily-available replaceable parts.</li>
          </ul>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Koenen Test Apparatus",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS manufactures the Koenen Test Apparatus for UN/DOT testing. The apparatus includes remote operation capability."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.about {
}
</style>
